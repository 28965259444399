import React, {useState} from 'react'
import {generateUUID} from "../../utils/generateUUID";
import {Link} from "react-router-dom";

import themeDark from "../../images/themes/dark/theme.png"
import themeLight from "../../images/themes/light/theme.png"

import logoDark from "../../images/themes/dark/logo.png"
import logoLight from "../../images/themes/light/logo.png"
import {useDispatch, useSelector} from "react-redux";
import {updateSettingsAPI} from "../../store/localSettingsSlice";
import {addToast} from "../../store/toastSlice";

export default function Header() {
    const settings = useSelector((state) => state.settings);
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const globalLinks = [{
        "link": "/",
        "title": "Home"
    }]

    const loggedOutLinks = [{
        "link": "/login",
        "title": "Login"
    },]

    const loggedInLinks = [{
        "link": "/dashboard",
        "title": "Dashboard"
    },{
        "link": "/logout",
        "title": "Logout"
    },]

    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light-mode');

    const toggleTheme = () => {

        // Update the theme via API only if the user is logged in
            const newTheme = theme === 'light-mode' ? 'dark-mode' : 'light-mode';
            document.getElementById('root').className = newTheme;
            setTheme(newTheme); // Update the local theme state
            dispatch(updateSettingsAPI({theme: newTheme}));

            dispatch(addToast({
                id: Date.now(),
                message: 'Successful updated your Theme Setting!',
                type: 'success'
            }));

            console.log("Theme updated to:", newTheme);
    };

    return (
        <header>
            <div className={"leftContainer"}>
                <Link to="/" className={"logoContainer"}>
                    {theme === "light-mode" &&
                        <img src={logoLight} alt="White Logo"/> ||
                        <img src={logoDark} alt="Black Logo"/>
                    }
                    <h1>
                        UXNETWORK SERVICE
                    </h1>
                </Link>
                <div onClick={toggleTheme} className={"toggleTheme"}>
                    {theme === "light-mode" &&
                        <img src={themeLight} alt="sun"/> ||
                        <img src={themeDark} alt="moon"/>
                    }
                </div>
            </div>
            <div>
                {
                    globalLinks.map((link) => (
                        <Link to={link.link} key={generateUUID()} title={link.title}>
                            <button>{link.title}</button>
                        </Link>
                    ))
                }
                {
                    (settings.isLoggedIn && user) ? loggedInLinks.map((link) => (
                        <Link to={link.link} key={generateUUID()} title={link.title}>
                            <button>{link.title}</button>
                        </Link>
                    )) : loggedOutLinks.map((link) => (
                        <Link to={link.link} key={generateUUID()} title={link.title}>
                            <button>{link.title}</button>
                        </Link>
                    ))
                }
            </div>
        </header>
    )
}
